import React from "react";
import Shop from "../components/shop/Shop";

const Home = () => {
  return (
    <main className="bg-[#f5f5f5] ">
 
      <Shop />
    </main>
  );
};

export default Home;
