import React from 'react';
import Shop from './Shop';

const Category = () => {
    return (
        <div>
          <Shop></Shop>
            
        </div>
    );
};

export default Category;