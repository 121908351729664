import { useState, useEffect } from "react";

const useOrderData = (nav, refetch, u, page, size) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const url = `https://storeserver.niroghealthplus.com/api/v1/order/specific?fieldName=${"status"}&&fieldValue=${nav}&&size=${size}&&page=${page}`;
      try {
        const response = await fetch(url);
        const responseData = await response.json();
        setData(responseData?.data);
        setFilterData(responseData?.data);
        setQuantity(responseData?.total);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        // You may handle the error differently based on your requirements
        console.error(err);
      }
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000); // Fetch data every 5 seconds

    return () => clearInterval(intervalId);
  }, [nav, refetch, u, page, size]);

  return { loading, data, filterData, quantity };
};

export default useOrderData;
