import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import axios from "axios";
import { Blurhash } from "react-blurhash";
import "./Slider.css";
import Loading from "../../Shared/Loading";

const BannerSlider = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://storeserver.niroghealthplus.com/api/v1/slider/getSliders"
      );
      const newData = response.data.data;
      setImages(newData);
      localStorage.setItem("sliderImages", JSON.stringify(newData));
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const storedData = localStorage.getItem("sliderImages");
    if (storedData) {
      setImages(JSON.parse(storedData));
      setLoading(false);
    } else {
      fetchData();
    }

    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="pt-[8.75rem] md:pt-[12.5rem] xl:pt-[7rem]">
      {loading ? (
        <Loading />
      ) : (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{ delay: 2500, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {images.map((image) => (
            <SwiperSlide className="overflow-hidden" key={image._id}>
              {!imageLoaded && (
                <div className="w-full h-full">
                  <Blurhash
                    hash="LII~@wadInIn00j]IBxa^nt7?0n+"
                    width={1304}
                    height={380}
                    className="w-full h-full"
                  />
                </div>
              )}
              <img
                className={`w-full h-full ${
                  imageLoaded ? "opacity-100" : "opacity-0"
                }`}
                src={image.sliderImg}
                alt="SliderImage"
                onLoad={() => setImageLoaded(true)} // Set imageLoaded to true when the image is loaded
                loading="lazy" // Lazy load images
                // Preload images for the next slide
                rel="preload"
                as="image"
                href={images[images.indexOf(image) + 1]?.sliderImg}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default BannerSlider;
