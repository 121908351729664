import React, { useState } from "react";
import Rating from "react-rating";
import { IoIosStar } from "react-icons/io";
import { FaRegStar } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { Blurhash } from "react-blurhash";

const TopRelatedProducts = ({ product }) => {
  const discountedPrice =
    product?.onePiecePrice - (product?.onePiecePrice * product?.discount) / 100;
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Link
      to={`/product/${product?.slug}`}
      className="border-b border-solid border-gray-300"
    >
      <div className="flex items-center gap-3 pb-3 mb-3 border-b border-solid border-gray-300">
        {/* Render Blurhash if image is not loaded */}
        {!imageLoaded && (
          <Blurhash
            hash="LII~@wadInIn00j]IBxa^nt7?0n+"
            width="4.0625rem"
            height="4.0625rem"
            className="border border-solid border-gray-300 w-20 h-20"
          />
        )}
        {/* Product image */}
        <img
          className={`border border-solid border-gray-300 w-20 h-20 transition-opacity duration-500 ${
            imageLoaded ? "opacity-100" : "opacity-0"
          }`}
          src={product?.img}
          alt="productImage"
          onLoad={() => setImageLoaded(true)}
          onError={() => setImageLoaded(true)}
          decoding="async"
          width="4.0625rem"
          height="4.0625rem"
        />

        <div>
          {/* Product name */}
          <h4 className="font-openSans text-sm text-gray-600">
            {product?.name}
          </h4>
          {/* Product rating */}
          <Rating
            fullSymbol={<IoIosStar className="text-primary" />}
            emptySymbol={<FaRegStar className="text-primary text-center" />}
            initialRating={5}
            readonly
          />
          {/* Product prices */}
          <p className="font-medium font-rubic text-sm">
            <span className="line-through">৳ {product?.onePiecePrice}</span> ৳{" "}
            {discountedPrice.toFixed(2)}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default TopRelatedProducts;
