import { useState, useEffect } from "react";
import axios from "axios";

function useProductData(page, size) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    let isMounted = true;

    const loadDataFromCache = () => {
      const cachedData = localStorage.getItem("productDataTable");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setProducts(parsedData.products);
        setQuantity(parsedData.quantity);
        setLoading(false);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `https://storeserver.niroghealthplus.com/api/v1/product/specific?page=${page}&size=${size}`
        );

        const responseData = response?.data?.data;
        const totalQuantity = response?.data?.total;

        // Save to cache
        localStorage.setItem(
          "productDataTable",
          JSON.stringify({ products: responseData, quantity: totalQuantity })
        );

        if (isMounted) {
          setProducts(responseData);
          setQuantity(totalQuantity);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    const syncWithServer = () => {
      fetchProducts(); // Fetch data from API to sync with server
    };

    loadDataFromCache(); // Attempt to load data from cache first
    fetchProducts(); // Fetch data from API

    // Set up interval to sync with server every 5 seconds
    const intervalId = setInterval(syncWithServer, 5000);

    return () => {
      isMounted = false;
      clearInterval(intervalId); // Clear the interval when component unmounts
    };
  }, [page, size]);

  return { loading, error, products, quantity };
}

export default useProductData;
